import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { AppComponent } from './components/app';
import { LoginComponent } from './components/login';
import { LoginService } from './services/login';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpModule
    ],
    declarations: [
        AppComponent,
        LoginComponent
    ],
    providers: [ 
        LoginService 
    ],
    bootstrap: [ AppComponent ]
})

export class AppModule {}