import { Component } from '@angular/core';
import { Login } from '../../models/login';
import { LoginService } from '../../services/login';

@Component({
    selector: 'login',
    template: require('./login.html'),
    providers: [LoginService]
})
export class LoginComponent {
    constructor(private login: LoginService){}
    username = '';
    onSubmit() {
        this.login.login(this.username).subscribe((res: Login) => {
            window.location.href = res.loginUrl;
        });
    }
} 