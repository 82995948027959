import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { Login } from '../models/login';
import 'rxjs/add/operator/map';

@Injectable()
export class LoginService {
    constructor(private http: Http) { }

    login(username: String) : Observable<Login> {
        return this.http.post('/api/login', { email: username }).map((res : Response) => res.json());
    }
}