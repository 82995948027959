import 'core-js/es6';
import 'core-js/es7/reflect';
import 'zone.js/dist/zone';

import './styles/main.scss';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { AppModule } from './app/module';

if(process.env.NODE_ENV === 'prod') {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);